import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function usePortfolioTransactions() {
  const portfolioTransactions = ref([])
  const totalPortfolioTransactions = ref(0)
  const loading = ref(false)
  const searchQuery = ref('')
  const isDialogVisible = ref(false)
  const companies = ref(['Company 1', 'Company 2', 'Company 3'])
  const selectedCompany = ref('')

  const headers = [
    { text: 'Customer', value: 'name' },
    { text: 'Security', value: 'security' },
    { text: 'Buy/Sell', value: 'details' },
    { text: 'Exchange', value: 'exchange' },
    { text: 'Investment Class', value: 'investmentClass' },
    { text: 'Execution Date', value: 'date' },
    { text: 'Comments', value: 'comment' },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })

  const fetchPortfolioTransactions = () => {
    store
      .dispatch('pa-dealing/fetchPortfolioTransactions', {
        q: searchQuery.value,
        options: options.value,
      })
      .then(data => {
        portfolioTransactions.value = data
        totalPortfolioTransactions.value = data.length

        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, options], () => {
    loading.value = true
    fetchPortfolioTransactions()
  })

  return {
    headers,
    options,
    portfolioTransactions,
    totalPortfolioTransactions,
    isDialogVisible,
    companies,
    selectedCompany,
    searchQuery,
    loading,
  }
}
